<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-first_name" class="form-label">
                        Имя
                        <sup v-if="v$.form.first_name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.first_name.$errors.length" for="form-first_name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.first_name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-first_name"
                        v-model="form.first_name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.first_name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-last_name" class="form-label">Фамилия</label>
                    <input
                        id="form-last_name"
                        v-model="form.last_name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-phone" class="form-label">
                        Телефон
                        <sup v-if="v$.form.phone.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.phone.$errors.length" for="form-phone" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.phone.$errors[0].$message) }}
                    </label>

                    <PhoneComponent
                        id="form-phone"
                        :value="form.phone"
                        name="phone"
                        :error="v$.form.phone.$errors.length"
                        @change="handleChangePhone"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-email" class="form-label">Email</label>
                    <input
                        id="form-email"
                        v-model="form.email"
                        type="email"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-city" class="form-label">Город</label>
                    <input
                        id="form-city"
                        v-model="form.city"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12 flex items-center">
                    <input
                        id="form-active"
                        v-model="form.is_search_city"
                        class="show-code form-check-switch flex-none mr-0"
                        type="checkbox"
                    />
                    <label for="form-active" class="form-check-label">
                        Применять выбранный город в фильтре круизов
                    </label>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="#" @click.prevent="redirectToMainPage" class="btn btn-secondary w-24 mb-2 mr-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { customersMixin } from '@/mixins/form/customers-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import { helper as $h } from '@/utils/helper';
import { phoneClear } from '@/utils/phone';
import PhoneComponent from '@/components/base/PhoneComponent.vue';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: { PhoneComponent, Preloader },
    mixins: [customersMixin, errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                first_name: { required },
                phone: { required },
            },
        };
    },
    data() {
        return {
            changePassword: false,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Покупатели / Редактировать');
        await this.fetchCustomer();
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                await this.axios.post(
                    '/customers/' + this.$route.params.id,
                    { ...this.form, phone: phoneClear(this.form.phone) },
                    {
                        params: {
                            _method: 'patch',
                        },
                    }
                );
                this.loading = false;

                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    this.redirectToMainPage();
                    // await this.$router.push({ name: 'customers' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        handleChangePhone(value) {
            if (typeof value === 'string') this.form.phone = value;
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('customers');
        },
    },
};
</script>

<style scoped lang="scss">
.change__password {
    position: absolute;
    top: 42%;
    right: 0;
}
</style>
